@CHARSET "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,
form,fieldset,input,textarea,p,blockquote,th,td {
    padding: 0;
    margin: 0;
}
a{
	text-decoration:none;
}
table {
    border-spacing: 0;
}
fieldset,img {
    border: 0;
}
address,caption,cite,code,dfn,em,strong,th,var {
    font-weight: normal;
    font-style: normal;
}
strong{
	font-weight: bold;
}

caption,th {
    text-align: left;

}
h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    font-size: 100%;
    margin:0;
    padding:0;
}
q:before,q:after {
    content:'';
}
abbr,acronym { border: 0;
}

* { 
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
  box-sizing: border-box;
}

:focus, :active {
  outline: 0;
}

/* == disabilita colorazione diversa dei link autogenerati da Apple ===================================== */

a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
}

/* Custom CSS
 * --------------------------------------- */
html{
	width: 100%;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: hidden;
	position: fixed;
}

body{
	font-family: LinotypeUniversW01-Ligh_723631,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
	color: #333333;
	overflow-x: hidden;
	overflow-y: hidden;
	width: 100%;
	position: fixed;
	height: 100%;
    text-rendering: optimizeLegibility!important;
    -webkit-font-smoothing: antialiased!important;
    -moz-osx-font-smoothing: grayscale!important;
}




.sp-2 { height: 2px;}
.sp-80 { height: 80px;}
.sp-120 { height: 120px;}
.sp-160 { height: 160px;}


 a {
	color: #3984BE;
}

/* header
* --------------------------------------- */

.hdr{
	width: 100%;
	height: 121px;
	padding: 0px;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
	z-index: 8000;
	position: fixed;
	background-color: #ffffff;
		-webkit-box-shadow: 0 3px 3px -3px rgba(0,0,0,.3);
		-moz-box-shadow: 0 3px 3px -3px rgba(0,0,0,.3);
		box-shadow: 0 3px 3px -3px rgba(0,0,0,.3);
}

.top-aligner {
	position: relative;
	width: 100%;
	max-width: 1080px;
	margin: 0 auto;
}

.logos {
	padding: 8px 0px;
	float: left;
	width: 50%;
	height: 80px;
}

.sx {
	text-align: left;
	padding-left: 16px;
}

.sx img{
	width: 100%;
	max-width: 135px;
	max-height: 64px;
}

.dx {
	text-align: right;
	padding-right: 16px;
}

.dx img{
	width: 100%;
	max-width: 141px;
	max-height: 64px;
}

.top-aligner:after { 
  content: "";
  clear: both;
  display: table;
}

.screen-info {
	background: #F8F8F8;
	width: 100%;
	content: "";
	clear: both;
	display: table;
	padding: 0px 16px
}

.update {
	padding: 8px 0px 8px 0px;
	font-size: 14px;
	background: #F8F8F8;
	color: #333333;
}

.update  span {
	font-weight: bold; display: inline-block;
}

.ftr {
	width: 100%;
	height: 56px;
	padding: 0px;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
	z-index: 8000;
	position: fixed;
	bottom: 0px;
	background-color: #ffffff;
/*		-webkit-box-shadow: 0 -3px 3px -3px rgba(0,0,0,.3);
		-moz-box-shadow: 0 -3px 3px -3px rgba(0,0,0,.3);
		box-shadow: 0 -3px 3px -3px rgba(0,0,0,.3); */
}



.bottom-aligner {
	position: relative;
	width: 100%;
	max-width: 1080px;
	height: 56px;
	margin: 0 auto;
}

#myDropdown {
	max-width: 320px;
/*	height: 100vh;*/
	max-height: 100vh;
	padding: 0px;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
	position: absolute;
	bottom: 56px;
	left: 0px;
/*	padding-top: 56px; */
/*	background-color: #F6F6F6; */
	border-bottom: solid 1px #D8D8D8;
	z-index: 7000;
	overflow: auto;
}

.list {
	background-color: #FFFFFF;
	padding-top: 8px;
}

.sp-64 { width: 64px; height: 112px; 	background-color: rgba(0,0,0,.0)}

.dropbtn {
	position: absolute;
	bottom: 8px;
	left: 0px;
	cursor: pointer;
}

.dropbtn:hover {
	background-color: rgba(0,0,0,.0);
}

.dropbtn {
	position: absolute;
	bottom: 8px;
	left: 0px;
	cursor: pointer;
}

.all-on-map {
	position: absolute;
	bottom: 8px;
	right: 0px;
	cursor: pointer;
}

.dropup {
	padding: 0px;
	margin: 0px;
	width: 100%;
	height: 68px;
}

.cyclist {
	width: auto;
	height: 68px;
	margin: 0px 16px 0px 16px;
	padding: 8px 0px 8px 0px;
	border-bottom: solid 1px #D8D8D8;
	cursor: pointer;
	color: #333333;
	font-weight: regular;
}

.cyclist:after { 
  content: "";
  clear: both;
  display: table;
}

.dropup:last-of-type .cyclist {
	border-bottom: solid 1px rgba(0,0,0,.0);
}

.cyclist .pin, .cyclist svg, .cyclist p {
	float: left;
}

.cyclist .pin{
	width: 52px;
	height: 52px;
	border-radius: 50%;
	background: #666666;
}

.cyclist p {
	font-size: 16px;
	font-weight: bold;
	margin: 14px 0px 0px 8px;
}


iframe 	{width: 100%;height: 100%; border-bottom: solid 56px white; background-color: #e6E6E6;}


button {
	height: 44px;
	font-size: 16px;
	font-weight: bold;
    color: #31479d;
	cursor: pointer;
	background-color: rgba(0,0,0,.0);
	padding: 11px 20px 13px 20px;
	outline: none;
	box-sizing: border-box;
	margin: 4px 8px 0px 8px;
	border: none;
	font-family: LinotypeUniversW01-Ligh_723631,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
}


.share-icon {
	width: 48px;
	height: 48px;
    color: #31479d;
	cursor: pointer;
	background-color: #31479d;
	outline: none;
	box-sizing: border-box;
	border: none;
	position: absolute;
	bottom: 72px;
	left: 8px;
	background-image: url("share.png");	
	background-position: top left;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
	border-radius: 2px;
}



button.w3-button {
	position: absolute;
	bottom: 4px;
	right: 0px;
	cursor: pointer;
}

.w3-button:hover {
	background-color: rgba(0,0,0,.0);
}


@media only screen and (min-width: 1080px) {
#myDropdown {
	max-height: 100vh;
}
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.dialog-overlay {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: rgba(15,43,75,0.8);
	z-index: 999999;
}

.dialog-aligner
{
	padding: 0px 15px 0px 15px;
	width: 100%;
	height: 100vh;
	position: relative;
}

.dialog-overlay-whitebox {
	background-color: #FFFFFF;
	box-shadow: 0 0px 5px 0;
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	padding: 30px; 
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	word-wrap: break-word;
}

.dialog-overlay-header {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 15px;
}

.dialog-overlay-text {
	font-size: 16px;
}

.dialog-overlay-text a {
	display: block;
	padding: 15px 0px 15px 0px;
}

.loader {
	margin: 0 auto;
	margin-top: 0px;
/*	margin-left: 33%; */
	position: relative;
	border: 4px solid rgba(0,0,0,0); 
	border-top: 4px solid #3984BE;
	border-left: 4px solid #3984BE;
	border-right: 4px solid #3984BE;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    animation: spin 1s linear infinite;
}


.dialog-overlay-waitmessage {
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	padding: 30px; 
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	
	text-align: center;
	font-size: 18px;
	color: #FFFFFF;

}

.dialog-overlay-waitmessage .loader {
	margin: 0 auto;
	margin-top: 0px;
	margin-bottom: 32px;
/*	margin-left: 33%; */
	position: relative;
	border: 4px solid rgba(0,0,0,0); 
	border-top: 4px solid #FFFFFF;
	border-left: 4px solid #FFFFFF;
	border-right: 4px solid #FFFFFF;
    border-radius: 50%;
    width: 88px;
    height: 88px;
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}